import logo from './library-building-96.png';

import './App.css';


function App() {
  return (
    <div className="App">
      <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p className="header-text">Libraries</p>
          <p className="subHeaderText">share yours...</p>  
          <p className="functionsText">sell.loan.swap.donate</p>  
          {/* <Text style={styles.headerText}>Libraries</Text>
          <Text style={styles.subHeaderText}>share yours</Text> */}
      </header>
    </div>
  );
}

export default App;
